import React from "react"

export default function TwoGrid({
  children,
  className,
  image,
  reverse,
  stick,
}) {
  return (
    <div className={`grid md:grid-cols-2 gap-6 md:gap-12 ${className}`}>
      <div
        className={` ${
          stick ? "row-start-1" : "row-start-2"
        } flex flex-col gap-6 md:row-start-1 ${
          reverse ? " md:col-start-2" : ""
        }`}
      >
        {children}
      </div>
      <div
        className={` ${stick ? "row-start-2" : "row-start-1"} md:row-start-1 ${
          reverse ? "md:col-start-1" : ""
        }`}
      >
        {image}
      </div>
    </div>
  )
}
